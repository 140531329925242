<template>
    <CommonView/>
</template>

<script>
    import CommonView from "@/components/common/CommonView.vue";
    export default {
        name: "Sview",
        components: {
            CommonView
        }
    }
</script>

<style scoped>

</style>
